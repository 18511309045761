
      import {mdx} from '@mdx-js/react';

      /* @jsx mdx */
import {CmsPageBlogPostLayout} from 'layouts/CmsPageBlogPostLayout'


const layoutProps = {
  
};
const MDXLayout = CmsPageBlogPostLayout
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    {
      /* TODO Inserire i chilometri fra gli step dello svolgimento */
    }
    <p>{`When I need to take a break and I have nothing planned, I go out and I start riding through the hills looking for silence, nature and evocative views.`}</p>
    <p>{`This is the route I hit more often because it is `}<strong parentName="p">{`close to the center`}</strong>{` and it is also `}<strong parentName="p">{`very scenographic`}</strong>{`.`}</p>
    <p>{`Along these `}<strong parentName="p">{`roads with little traffic`}</strong>{` I can enjoy `}<strong parentName="p">{`spectacular views`}</strong>{` of the city of `}<strong parentName="p">{`Vicenza`}</strong>{`, the `}<strong parentName="p">{`Vicentine Alps`}</strong>{` on its background and the `}<strong parentName="p">{`Po Valley`}</strong>{` towards Padua. It can happen that I see roe deer if I am lucky and there is no traffic at all.`}</p>
    <p>{`The route is hilly. It starts in `}<strong parentName="p">{`Vicenza in the Gogna area`}</strong>{`, it ascends to `}<strong parentName="p">{`Mount Berico`}</strong>{`, it continues on the crest along the Dorsale dei Colli Berici passing through `}<strong parentName="p">{`Arcugnano`}</strong>{` and `}<strong parentName="p">{`Perarolo`}</strong>{`, it descends to `}<strong parentName="p">{`St. Agostino`}</strong>{` and it ends crossing the `}<strong parentName="p">{`Ferrovieri`}</strong>{` neighborhood.`}</p>
    <h2>{`Route Description`}</h2>
    <p>{`This route consists only of paved roads. It is suited to `}<strong parentName="p">{`road bikes`}</strong>{`, `}<strong parentName="p">{`mountain bikes`}</strong>{` and `}<strong parentName="p">{`gravel bikes`}</strong>{`.`}</p>
    <p>{`Almost all of these roads are well-preserved. At St. Agostino and along the Ferrovieri neighborhood, however, the road surface is ruined by many pits and cracks.`}</p>
    <h2>{`Route Progress`}</h2>
    <p>{`We immediately face the most challenging part of this route ascending towards `}<strong parentName="p">{`Mount Berico`}</strong>{`. It is a very steep climb, but luckily it is also short.`}</p>
    <p>{`At Mount Berico we find the `}<strong parentName="p">{`Church of St. Mary of Mount Berico`}</strong>{`, which was built after the Marian apparitions of 1426 AD and 1428 AD.`}</p>
    <p>{`I dare say it is mandatory to look down from the `}<strong parentName="p">{`terrace of Piazzale della Vittoria`}</strong>{`: we will enjoy Vicenza from above and, with clear weather, we will be amazed by the `}<strong parentName="p">{`wonderful landscape`}</strong>{` that shows us the north-eastern part of the Province with its `}<strong parentName="p">{`mountains`}</strong>{`.`}</p>
    <p>{`We pass by the `}<strong parentName="p">{`Museum of Risorgimento and Resistance at Villa Guiccioli`}</strong>{`, which hosts a park as well, and we continue with gentle ups and downs.`}</p>
    <p>{`After the `}<strong parentName="p">{`locality of Villa Margherita`}</strong>{` we descend abruptly for about 1 km.`}</p>
    <p>{`The next climb, steep but also short, takes us to `}<strong parentName="p">{`Arcugnano`}</strong>{`. This is the second challenge of this ride.`}</p>
    <p>{`We continue winding on a flat road for some kilometers.`}</p>
    <p>{`After the `}<strong parentName="p">{`locality of Spianzana`}</strong>{` we face the last of the three challenging climbs of this ride that, still winding, takes us to the hamlet of `}<strong parentName="p">{`Perarolo`}</strong>{`.`}</p>
    <p>{`At `}<strong parentName="p">{`Perarolo`}</strong>{` we can freshen from the `}<strong parentName="p">{`drinking water next to the war memorial`}</strong>{`. This is a perfect place to take a break before descending from the hills.`}</p>
    <p>{`We change direction after a few dozen meters `}<strong parentName="p">{`following directions for Brendola`}</strong>{`, taking the `}<strong parentName="p">{`Strada di Perarolo SP-129`}</strong>{`. We continue for only a few hundreds meters before turning right into Via Paolini, `}<strong parentName="p">{`following directions for Vicenza and St. Agostino`}</strong>{`.`}</p>
    <p>{`We descend rapidly towards the hamlet of `}<strong parentName="p">{`St. Agostino`}</strong>{`. The descent is very steep, so we need to stay extremely alert because `}<strong parentName="p">{`the curves are very sharp`}</strong>{`.`}</p>
    <p>{`We continue on a flat road without more challenges for the last few kilometers, passing through the `}<strong parentName="p">{`Ferrovieri neighborhood`}</strong>{`. This is the only part of the route where we must deal with the traffic.`}</p>
    <h2>{`Major Challenges`}</h2>
    <ul>
      <li parentName="ul">{`climb towards Mount Berico`}</li>
      <li parentName="ul">{`climb towards Arcugnano`}</li>
      <li parentName="ul">{`climb towards Perarolo`}</li>
      <li parentName="ul">{`sharp curves descending towards St. Agostino`}</li>
      <li parentName="ul">{`traffic during the rush hour along the last part of the route`}</li>
    </ul>
    <h2>{`Starting Point`}</h2>
    <p>{`The `}<strong parentName="p">{`square in front of the Church of St. Giorgio`}</strong>{` in the Gogna area, in Vicenza, is very convenient because cars can park for free. Cars can park for free also along the road.`}</p>
    <p>{`In the `}<strong parentName="p">{`Ferrovieri neighborhood`}</strong>{` the parking spaces are free of charge.`}</p>
    <p><strong parentName="p">{`Piazzale della Vittoria`}</strong>{` has a big parking area free of charge, but it can be very busy during the celebrations of the Holy Masses. If we start here, we will need to face the climb towards Mount Berico at the end of the route.`}</p>
    <h2>{`Places of Interest`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://en.wikipedia.org/wiki/Monte_Berico",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Church of St. Mary of Mount Berico`}</a></li>
      <li parentName="ul">{`Piazzale della Vittoria`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.comune.vicenza.it/cittadino/scheda.php/42724,218647",
          "target": "_blank",
          "rel": "noopener"
        }}>{`Museum of Risorgimento and Resistance at Villa Guiccioli`}</a></li>
      <li parentName="ul">{`park of Villa Guiccioli`}</li>
    </ul>
    <h2>{`Useful Stops`}</h2>
    <ul>
      <li parentName="ul">{`public toilets of Piazzale della Vittoria at Mount Berico`}</li>
      <li parentName="ul">{`a couple of bars at Mount Berico`}</li>
      <li parentName="ul">{`a few restaurants and pizzerias between Arcugnano and Perarolo`}</li>
      <li parentName="ul">{`drinking water next to the war memorial at Perarolo`}</li>
    </ul>

    </MDXLayout>;
}

;
MDXContent.isMDXComponent = true;
    